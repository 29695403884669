import React from "react";
import MetaTags from "react-meta-tags";
import { ReactTitle } from "react-meta-tags";
import { Helmet } from "react-helmet";
export default function Pages({ children, title, subtitle }) {
  return (
    <div className="pages">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subtitle} />
      </Helmet>
      <ReactTitle title={title} />
      <h4>{title}</h4>
      <div />
      {children}
    </div>
  );
}
