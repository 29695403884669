import React, {Component} from 'react';
import logo from '../images/name_logo_website.png';
import {FaAlignRight} from 'react-icons/fa';
import {Link} from 'react-router-dom'

export default class Navbar extends Component {
    state = {
        isOpen:false
    }
    handleToggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }
    render(){
        return(
        <nav className='navbar'>
            <div className='nav-center'>
                <div className='nav-header'>
                    <Link to="/">
                        <img src={logo}/>
                    </Link>
                    <button type='button' className='nav-btn' onClick={this.handleToggle}>
                        <FaAlignRight className='nav-icon'/>
                    </button>
                </div>
                <ul className={ this.state.isOpen? "nav-links show-nav" : "nav-links" }>
                    <li>
                        <Link to='/' onClick={this.handleToggle}>Home</Link>
                    </li>
                    <li>
                        <Link to='/Services' onClick={this.handleToggle}>Services</Link>
                    </li>
                    <li>
                        <Link to='/About' onClick={this.handleToggle}>About</Link>
                    </li>
                    <li>
                        <Link to='/Contact' onClick={this.handleToggle}>Contact</Link>
                    </li>

                </ul>
            </div>

        </nav>
        );
    }
}
