import React from "react";
import Page from "../components/Pages"
import Service from "../components/Services"
import Footer from "../components/Footer"
export default function Services() {
    return (
        <Service>
        <Footer />
        </Service>
    );
}