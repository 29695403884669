import React, {Component} from 'react';
import {FaCopyright} from 'react-icons/fa';
export default class Footer extends 
Component {
    render()
        {
            return(
                
               <footer class="footer">
                   <FaCopyright />2020 Vadranam.com
                   </footer> 
            );
        }
    
}