import React, {Component} from 'react';
import Title from './Title';
import {FaMobileAlt, FaDesktop, FaDatabase, FaAws } from 'react-icons/fa';
export default class Services extends 
Component{
    state={
        services:[
            {
            icon:<FaMobileAlt/>,
            title:"Mobile Services",
            description:"The use of cloud-based technology has risen as a result of its benefits. Developing mobile apps on the cloud has many advantages, such as flexibility, cost-effectiveness, standardization, agility, etc. Companies hire an iOS app developer to take advantage of the benefits that cloud technology presents. Developers can perform app development using cloud-based development tools from anywhere in the world. The onboarding process for new users is smooth and fast, which is a win-win for app developers."
            },
            {
                icon:<FaDesktop/>,
                title:"Desktop Services",
                description:"Electron enables you to create desktop applications with pure JavaScript by providing a runtime with rich native (operating system) APIs. You could see it as a variant of the Node.js runtime that is focused on desktop applications instead of web servers. This doesn't mean Electron is a JavaScript binding to graphical user interface (GUI) libraries. Instead, Electron uses web pages as its GUI, so you could also see it as a minimal Chromium browser, controlled by JavaScript."
             },
            {
                icon:<FaDatabase/>,
                title:"Data Services",
                description:"Database management can be a complex and expensive operation. Agile-minded and DevOps teams aren’t happy with the slow progress of database development. Database as a Service (DBaaS) is here to the rescue, infusing a breath of flexibility into database management and cloud migration."     },
            {
                icon:<FaAws/>,
                title:"Cloud Services",
                description:"The cloud-first mentality is a set of business practices that aims to utilize cloud services as much as possible. When a cloud-first enterprise establishes new processes or reviews existing ones, they consider a cloud computing solution before any others."   
                   
            }
         ]
    }
    render() {
        return(
            <section className="services">
            <Title title="services" />
            <div className="services-center">
                {this.state.services.map((item, index) => {
                    return <article key={index} className="service">
                        <span>{item.icon}</span>
                        <h6>{item.title}</h6>
                        <p>{item.description}</p>
                    </article>
                })}
            </div>
            </section>
        );
    }
}