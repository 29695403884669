import React from 'react';

export default function Banner(
    {
        children, 
        title, 
        subtitle1, 
        subtitle2,
        subtitle3,
        subtitle4,
        subtitle5
    }
    )
{
    return(
    <div className="banner">
        <h1>{title}</h1>
        <div />
        <p>{subtitle1}</p>
        <p>{subtitle2}</p>
        <p>{subtitle3}</p>
        <p>{subtitle4}</p>
        <p>{subtitle5}</p>
        {children}
    </div>
    );
}