import React, {Component} from 'react';
import Title from './Title';
import {FaDashcube, FaSmile, FaPoll, FaMicrochip, FaHandshake, FaTools, FaGlobe } from 'react-icons/fa';
export default class Values extends
Component{
    state={
        services:[
            {
            icon:<FaDashcube/>,
            title:"#Adapt",
            description:"Our team adapts to the changes in technology and focus on continuous learning"
            },
            {
                icon:<FaSmile/>,
                title:"#Smile",
                description:"Greet with respect and smile"
                },
            {
                icon:<FaPoll/>,
                title:"#Results",
                description:"Always focus on result oriented"
                   
            },
            {
                icon:<FaMicrochip/>,
                title:"#Tech",
                description:"The cloud-first mentality is a set of business practices that aims to utilize cloud services as much as possible. When a cloud-first enterprise establishes new processes or reviews existing ones, they consider a cloud computing solution before any others."

            },
            {
                icon:<FaTools/>,
                title:"#Innovation",
                description:"The cloud-first mentality is a set of business practices that aims to utilize cloud services as much as possible. When a cloud-first enterprise establishes new processes or reviews existing ones, they consider a cloud computing solution before any others."

            },
            {
                icon:<FaHandshake/>,
                title:"#Partenership",
                description:"The cloud-first mentality is a set of business practices that aims to utilize cloud services as much as possible. When a cloud-first enterprise establishes new processes or reviews existing ones, they consider a cloud computing solution before any others."

            },
             {
                icon:<FaGlobe/>,
                title:"#World Class",
                description:"The cloud-first mentality is a set of business practices that aims to utilize cloud services as much as possible. When a cloud-first enterprise establishes new processes or reviews existing ones, they consider a cloud computing solution before any others."

            }
         ]
    }
    render() {
        return(
            <section className="info">
            <Title title="Values" />
            <div className="info-center">
                {this.state.services.map((item, index) => {
                    return <article key={index} className="service">
                        <span>{item.icon}</span>
                        <h6>{item.title}</h6>
                        <p>{item.description}</p>
                    </article>
                })}
            </div>
            </section>
        );
    }
}