import React from 'react';
import Page from '../components/Pages';
import Footer from "../components/Footer"
import MapsContainer from "../components/MapsContainer"
import {FaPhone, FaEnvelope } from 'react-icons/fa';

export default function Contact(){
      return (
      <Page title="contact us">
      <p>If you have questions regarding our services or your employer is partnered with us and you want to know more then we'd love to help</p>
      <br/><p><FaEnvelope/> info@vadranam.com</p>
      <p><FaPhone/> 02034884718</p>
      <Footer />
      </Page>)
}
