import React from "react";
import Page from "../components/Pages";
import Footer from "../components/Footer";
import Values from "../components/Values";

export default function About() {
  return (
    <Page title="about us">
      <p>
        Since 2018, Vadranam Limited has helped global companies to streamline
        and simplify their development and testing process by following latest
        dev ops tools and processes – allowing them to serve more customers
        reliable applications running on mobile, web and desktop platforms.
      </p>

      <p>
        We believe new technologies are the lifeline of every business in the
        modern age and aim to connect businesses across all industries to
        innovative software, technological development, solutions and services,
        in a manner that’s faster, easier and better than ever before.
      </p>

      <Footer />
    </Page>
  );
}
