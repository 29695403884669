import React from "react";
import "./App.css";
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services/" component={Services} />
        <Route exact path="/about/" component={About} />
        <Route exact path="/contact/" component={Contact} />
        <Route component={Error} status={404} />
      </Switch>
    </>
  );
}

export default App;
