import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import Services from "../components/Services";
import Footer from "../components/Footer";
import MapsContainer from "../components/MapsContainer";
import { Helmet } from "react-helmet";
export default function Home() {
  return (
    <>
      <Helmet>
        <title>Vadranam Limited</title>
        <meta
          name="description"
          content="We work with ambitious business owners help to shape their business for tomorrow."
        />
      </Helmet>
      <Hero>
        <Banner
          title="Consultancy Services"
          subtitle1="Cloud Computing"
          subtitle2="Serverless Applications"
          subtitle3="Operational Excellence"
          subtitle4="Security"
          subtitle5="Reliability"
          subtitle6="Performance Efficiency"
        >
          <Link to="/Services" className="btn-primary">
            our services
          </Link>
        </Banner>
      </Hero>
      <Services />
      <Footer />
    </>
  );
}
